.wedge-team {
  clip-path: polygon(0% 8%, 100% 0%, 100% 92%, 0 100%);
}

@media only screen and (max-width: 640px) {
  .wedge-team {
    clip-path: polygon(0% 2%, 100% 0%, 100% 98%, 0 100%);
  }
}

.image-container {
  width: 25%;
  padding: 20px;
}

.square-rounded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10%;
}



#light .team {
  background-color: var(--sand)
}

#dark .team {
  background-color: var(--green-dark)
}