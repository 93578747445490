.image-div img {
  height: 300px;
  transition: all 0.2s ease-in-out;

}

.image-div {
  width: 100%;
  height: 100%;

}