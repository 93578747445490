#light .footer-style {
  color: var(--orange-light)
}

#light .footer-style:hover {
  color: var(--dark)
}

#dark .footer-style {
  color: var(--orange-light)
}

#dark .footer-style:hover {
  color: var(--sand);
}