.modal {
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  #dark .modal-gradient{
    background: linear-gradient(to top, var(--green-light), #022926);

  }
  #light .modal-gradient{
    background: linear-gradient(to bottom, var(--light), #fff);
  }
  
  #dark .modal-input{
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 16px;
    -webkit-transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, -webkit-box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, -webkit-box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: #0d0c22;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #light .modal-input{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 16px;
    -webkit-transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, -webkit-box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, -webkit-box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: #0d0c22;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #dark .modal-input:hover{
    border-color: rgba(0,0,0,0.1);
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 4px #efc47347;
    box-shadow: 0 0 0 4px #EFC473;
  }
  #light .modal-input:hover{
    border-color: rgba(0,0,0,0.1);
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 4px #efc47353;
    box-shadow: 0 0 0 4px #efc47353;
  }


  .modal-grid{
    display:grid;
    width:100%;
    grid-template-columns: .5fr 2fr .5fr;
  }