@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dark: #031E17;
  --green-dark: #02030C;
  --green-light: #012320;
  --light: #FEFEFC;
  --light-text: #FFFDF5;
  --sand: #FFFBEB;
  --orange-light: #EFC473;
  --orange-dark: #F2A413;
  --heading: 40px;
}

#light {
  background-color: var(--light);
}

#dark {
  background-color: var(--dark);
}

#light .text {
  color: var(--dark);
}

#dark .text {
  color: var(--sand);
}

#light .text-flip {
  color: var(--sand);
}

#dark .text-flip {
  color: var(--dark);
}

#light .banner {
  background-color: var(--dark)
}

#dark .banner {
  background-color: var(--sand)
}

.text-big {
  font-weight: bold;
}

.text-med {
  font-weight: 600;
}

.text-narrow {
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-wide {
  margin: auto;
  width: 90%;
  max-width: 1536px;
}

.container-mid {
  margin: auto;
  width: 100%;
  max-width: 1100px;
}

.container-nav {
  margin: auto;
  width: 100%;
  max-width: 680px;
}

@media only screen and (max-width: 1280px) {
  .container-wide {
    margin: auto;
    width: 90%;
    max-width: 1024px;
  }

  .container-mid {
    margin: auto;
    width: 100%;
    max-width: 900px;
  }

  .container-nav {
    margin: auto;
    width: 100%;
    max-width: 680px;
  }
}

@media only screen and (max-width: 1024px) {
  .container-wide {
    margin: auto;
    width: 90%;
    max-width: 768px;
  }

  .container-mid {
    margin: auto;
    width: 100%;
    max-width: 768px;
  }

  .container-nav {
    margin: auto;
    width: 100%;
    max-width: 680px;
  }
}

@media only screen and (max-width: 640px) {
  .container-wide {
    margin: auto;
    width: 90%;
    max-width: 480px;
  }

  .container-mid {
    margin: auto;
    width: 100%;
    max-width: 480px;
  }

  .container-nav {
    margin: auto;
    width: 100%;
    max-width: 480px;
  }
}

.button-dark {
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: var(--dark);
}

#light .button-dark:hover {
  background-color: var(--orange-dark);
  color: var(--light-text) !important;
}

#dark .button-dark:hover {
  background-color: var(--dark);
  color: var(--light-text) !important;
  border-color: transparent;
}


#light .button-dark-flip {
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: var(--dark);
}

#light .button-dark-flip:hover {
  background-color: var(--dark);
  color: var(--light-text);
  border-color: transparent;
}

#dark .button-dark-flip {
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: var(--light-text);
}

#dark .button-dark-flip:hover {
  background-color: var(--orange-dark);
  color: var(--light-text) !important;
}

.scale {
  transform: scale(100%);
  transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.scale:hover {
  transform: scale(120%);

}


.hover-effect {
  animation-play-state: paused;
  box-shadow: none;
}

@keyframes hover-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

.hover-effect:hover {
  animation: hover-animation 2s ease-in-out infinite;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
